import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { getEarning } from '@/api/investment';
import { options2 } from './options2';
export default {
  __name: 'investment_earn',
  setup(__props) {
    const queryForm = ref({
      user_address: '',
      page: 1,
      page_size: 15
    });
    const tableData = ref([]);
    const total = ref(0);
    const iniInvestmentEarningList = async () => {
      const res = await getEarning(queryForm.value);
      total.value = res.pager.total_rows;
      tableData.value = res.list;
    };
    iniInvestmentEarningList();
    const handleSizeChange = pageSize => {
      queryForm.value.page = 1;
      queryForm.value.page_size = pageSize;
      iniInvestmentEarningList();
    };
    const handleCurrentChange = pageNum => {
      queryForm.value.page = pageNum;
      iniInvestmentEarningList();
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createBlock(_component_el_card, null, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20,
          class: "header"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 7
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              placeholder: _ctx.$t('table.placeholder_user_address'),
              clearable: "",
              modelValue: queryForm.value.user_address,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => queryForm.value.user_address = $event)
            }, null, 8, ["placeholder", "modelValue"])]),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            icon: _unref(Search),
            onClick: iniInvestmentEarningList
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('table.search')), 1)]),
            _: 1
          }, 8, ["icon"])]),
          _: 1
        }), _createVNode(_component_el_table, {
          data: tableData.value,
          stripe: "",
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options2), (item, index) => {
            return _openBlock(), _createElementBlock(_Fragment, null, [!item.hide ? (_openBlock(), _createBlock(_component_el_table_column, {
              width: item.width,
              prop: item.prop,
              label: item.label,
              key: index
            }, null, 8, ["width", "prop", "label"])) : _createCommentVNode("", true), _createTextVNode("> ")], 64);
          }), 256))]),
          _: 1
        }, 8, ["data"]), _createVNode(_component_el_pagination, {
          "current-page": queryForm.value.page,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => queryForm.value.page = $event),
          "page-size": queryForm.value.page_size,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = $event => queryForm.value.page_size = $event),
          "page-sizes": [15, 20, 30, 40],
          small: _ctx.small,
          disabled: _ctx.disabled,
          background: _ctx.background,
          layout: "total, sizes, prev, pager, next, jumper",
          total: total.value,
          onSizeChange: handleSizeChange,
          onCurrentChange: handleCurrentChange
        }, null, 8, ["current-page", "page-size", "small", "disabled", "background", "total"])]),
        _: 1
      });
    };
  }
};