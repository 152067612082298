export const options2 = [
  {
    label: 'id',
    prop: 'Id',
    width: 70
  },
  {
    label: '用户id',
    prop: 'UserId',
    width: 70
  },
  {
    label: '用户钱包地址',
    prop: 'UserAddress',
    width: 390
  },
  {
    label: '托管套餐id',
    prop: 'RecordId',
    width: 120
  },
  {
    label: '收益',
    prop: 'Earning',
    width: 120
  },
  {
    label: '创建时间',
    prop: 'CreatedAt'
  }
]
